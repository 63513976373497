<template lang="pug">
om-select#language-chooser.language-chooser-select(:options="languages" v-model="selectedLanguage")
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import AVAILABLE_TEMPLATE_LANGUAGES from '@/graphql/AvailableTemplateLanguages.gql';
  import UPDATE_PREFERRED_TEMPLATE_LANGUAGE from '@/graphql/UpdatePreferredTemplateLanguage.gql';
  import PREFERRED_TEMPLATE_LANGUAGE from '@/graphql/PreferredTemplateLanguage.gql';
  import { track } from '@/services/xray';
  import LogRocket from 'logrocket';

  export default {
    data: () => ({
      availableTemplateLanguages: {},
    }),
    computed: {
      ...mapState(['preferredTemplateLanguage']),
      languages() {
        return Object.keys(this.availableTemplateLanguages).map((code) => ({
          value: this.$t(`translateFeature.languages.${code}`),
          key: code,
        }));
      },
      selectedLanguage: {
        get() {
          return { key: this.preferredTemplateLanguage };
        },
        async set(v) {
          if (v.key === this.preferredTemplateLanguage) return;

          this.track('language-chooser', { chosenLanguage: v.key });
          await this.$apollo.mutate({
            mutation: UPDATE_PREFERRED_TEMPLATE_LANGUAGE,
            variables: {
              languageCode: v.key,
            },
          });
          this.updatePreferredTemplateLanguage(v.key);
        },
      },
    },
    async mounted() {
      await this.getPreferredTemplateLanguage();
      await this.getAvailableTemplateLanguages();
    },
    methods: {
      ...mapMutations(['setPreferredTemplateLanguage']),
      updatePreferredTemplateLanguage(languageCode) {
        this.setPreferredTemplateLanguage(languageCode);
        this.$emit('change', languageCode);
      },
      async getPreferredTemplateLanguage() {
        const { data } = await this.$apollo.query({
          query: PREFERRED_TEMPLATE_LANGUAGE,
        });
        this.updatePreferredTemplateLanguage(data.preferredTemplateLanguage);
      },
      async getAvailableTemplateLanguages() {
        const { data } = await this.$apollo.query({
          query: AVAILABLE_TEMPLATE_LANGUAGES,
        });
        this.availableTemplateLanguages = data.availableTemplateLanguages;
      },
      track(eventName, props) {
        track(eventName, props);
        const logRocketInited = window._logRocketInited;
        if (logRocketInited) {
          LogRocket.track(eventName, props);
        }
      },
    },
  };
</script>

<style scoped lang="sass"></style>
